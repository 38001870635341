import React from 'react'
import PageTitle from '../components/PageTitle'
import GiftCardForm from '../components/GiftCardForm'
import { Elements } from 'react-stripe-elements'
import Layout from '../components/layout'
const GiftCardsPage = ({ data }) => (
  <Layout>
    <PageTitle header="Gift Card Order Form" />
    <Elements>
      <GiftCardForm />
    </Elements>
  </Layout>
)

export default GiftCardsPage
